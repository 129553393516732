import React from 'react'
import useStyles from './suggestions-style'
import {
  Title,
  SuggestionsCarousel,
} from '../../../../ui'

const SuggestionsView = ({ t, relatedProducts, onClickTrackEvent }) => {
  const carouselMaxWidth = `${20 * Math.min(5, relatedProducts.length)}rem`
  const styles = useStyles({ carouselMaxWidth })
  return (
    <div className={styles.suggestions}>
      <Title
        margin="0 0 1.2rem"
        text={t('screens.product.like')}
      />
      <SuggestionsCarousel
        relatedProducts={relatedProducts}
        onClickTrackEvent={onClickTrackEvent}
      />
    </div>
  )
}

export default SuggestionsView
