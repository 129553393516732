import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  colorPopover: () => ({
  }),
  colorStyle: {
    display: 'inline-block',
    overflow: 'hidden',
    width: '4.8rem',
    height: '5.2rem',
    cursor: 'pointer',
    position: 'relative',
    '&::after': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      borderWidth: '0.1rem',
      borderStyle: 'solid',
      borderColor: theme.colors.secondary,
      opacity: 0,
    },
  },
  colorSelectedStyle: {
    '&::after': {
      opacity: 1,
    },
  },
  colorSwatchImage: {
    margin: 0,
  },
  popoverStyle: {
    backgroundColor: theme.colors.text,
    padding: '0.5rem 2.2rem',
    '& span': {
      color: theme.colors.background,
      fontSize: '1.4rem',
    },
  },
}))

export default useStyles
