import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    width: '100%',
    padding: [[0, 20, 0]],
    minHeight: '60rem',
    // overflow: 'hidden',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      padding: [[15, 0, 0]],
      paddingTop: '2.8rem',
      textAlign: 'center',
    },
  },
  container: {
    textAlign: 'center',
    maxWidth: theme.mediaQueries.desktop,
    margin: [[0, 'auto']],
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      padding: [[15, 0, 0]],
      paddingTop: '2.8rem',
      textAlign: 'center',
    },
  },
  containerLoading: {
    minHeight: '60vh',
  },
  boxProduct: {
    display: 'flex',
    flexDirection: 'column',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      flexDirection: 'row',
    },
  },
  boxImage: {
    flex: '1 0 100%',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      flex: '1 0 55%',
    },
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      flex: '1 1 670px',
    },
  },
  breadcrumb: {
    paddingTop: '3rem',
    marginBottom: '1.7rem',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      paddingTop: '2rem',
      marginBottom: '1.5rem',
    },
  },
  boxDetails: {
    flex: '1 0 100%',
    minWidth: '28rem',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      flex: '1 0 45%',
      padding: '0 20px',
    },
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      flex: '1 1 43%',
    },
  },
}))

export default useStyles
