/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import React, { useRef, useState } from 'react'
import Slider from 'react-slick'
import { useTheme } from 'react-jss'
import MagicSliderDots from 'react-magic-slider-dots'
import useStyles from './product-images-style'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-magic-slider-dots/dist/magic-dots.css';
import { ImageZoom, Button } from '../../../../ui'
import { combineClassNames } from '../../../../helpers'
import placeholderImage from '../../../../assets/images/icon_image_placeholder.png'
import ArrowRight from '../../../../assets/icons/icon_right_arrow.svg'
import ArrowLeft from '../../../../assets/icons/icon_left_arrow.svg'

const ProductImageView = ({ pictures = [], loading = false }) => {
  const sliderRef = useRef(null)
  const theme = useTheme()
  const defaultImagesZoom = _.times(_.size(pictures), _.constant(false))
  const [currentSlide, setCurrentSlide] = useState(0)
  const [imagesZoom, setImagesZoom] = useState(defaultImagesZoom)
  const placeholder = _.get(theme, 'config.placeholderImage', placeholderImage)
  const disableImageZoomDesktop = _.get(theme, 'config.ui.images.zoom.disableImageZoomDesktop')
  const disableImageZoomMobile = _.get(theme, 'config.ui.images.zoom.disableImageZoomMobile')
  const zoomSize = _.get(theme, 'config.ui.images.zoom.size', 2)

  const handleSliderNext = () => {
    sliderRef.current.slickNext()
  }
  const handleSliderPrev = () => {
    sliderRef.current.slickPrev()
  }
  const handleZoomIn = (index) => {
    let newArr = _.clone(imagesZoom)
    newArr[index] = true
    setImagesZoom(newArr)
  }
  const handleZoomReset = (index) => {
    let newArr = _.clone(imagesZoom)
    newArr[index] = false
    setImagesZoom(newArr)
  }
  const styles = useStyles({ disableImageZoomMobile, disableImageZoomDesktop })
  const sliderSettings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex)
      setImagesZoom(defaultImagesZoom)
    },
    draggable: _.every(imagesZoom, (zoom) => (!zoom)),
    swipe: _.every(imagesZoom, (zoom) => (!zoom)),
    appendDots: (dots) => <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />,
  }

  return (
    <div className={styles.productImage}>
      <div className={styles.mainWindow}>
        { loading && <div className={styles.loadingPlaceholder} />}
        { !loading && (
          pictures.length > 0 ? (
            <>
              <Slider {...sliderSettings} ref={sliderRef}>
                {
                  _.map(pictures, ({ galleryLarge }, index) => (
                    <div key={`productImage${index}`} className={styles.sliderItem}>
                      <div className={styles.imageZoom}>
                        <ImageZoom
                          src={galleryLarge}
                          alt={index}
                          scale={zoomSize}
                          zoom={imagesZoom[index]}
                          onRequestZoomIn={() => { handleZoomIn(index) }}
                          onRequestZoomReset={() => { handleZoomReset(index) }}
                        />
                      </div>
                      <div className={styles.imageWithoutZoom}>
                        <img src={galleryLarge} alt={index} />
                      </div>
                    </div>
                  ))
                }
              </Slider>
              { pictures.length > 1 && (
                <>
                  <Button
                    onClick={handleSliderNext}
                    iconImage={ArrowRight}
                    className={
                      combineClassNames([
                        styles.arrow,
                        styles.arrowRight,
                        (currentSlide + 1) >= pictures.length && styles.arrowDisabled,
                      ])
                    }
                    disabled={(currentSlide + 1) >= pictures.length}
                  />
                  <Button
                    onClick={handleSliderPrev}
                    iconImage={ArrowLeft}
                    className={
                      combineClassNames([
                        styles.arrow,
                        styles.arrowLeft,
                        currentSlide === 0 && styles.arrowDisabled,
                      ])
                    }
                    disabled={currentSlide === 0}
                  />
                </>
              )}
            </>
          ) : (
            <div className={styles.placeholder}>
              <img className={styles.placeholderImage} src={placeholder} alt="No image" />
            </div>
          )
        )}
      </div>

      <div className={styles.boxThumbs}>
        {
          _.map(pictures, ({ webThumb }, thumbIndex) => (
            <div
              key={`images-${thumbIndex}`}
              className={
                combineClassNames([
                  styles.thumb,
                  currentSlide === thumbIndex && styles.thumbSelected,
                ])
              }
              onClick={() => sliderRef.current.slickGoTo(thumbIndex)}
            >
              <img src={webThumb} alt={thumbIndex} className={styles.thumbImage} />
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default ProductImageView
