import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  reviews: () => ({
    width: 'auto',
    maxWidth: '100%',
    marginTop: `${theme.gap}rem`,
    display: 'flex',
    flexDirection: 'column',
  }),
  hasMoreReviewsButton: {
    marginTop: 30,
    textAlign: 'left',
    fontSize: 14,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    reviews: () => ({
      marginTop: `${theme.doubleGap}rem`,
      maxWidth: '51rem',

    }),
  },

}))

export default useStyles
