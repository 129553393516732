/* eslint-disable react/no-danger */
import React from 'react'
import useStyles from './more-info-style'
import { Title } from '../../../../ui'

const MoreInfoView = ({ t, htmlContent }) => {
  const { moreInfo, content } = useStyles()
  return (
    <div className={moreInfo}>
      <Title
        text={t('screens.product.description')}
        margin="0 0 1.2rem"
      />
      <article className={content} dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  )
}

export default MoreInfoView
