/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import {
  useUser,
} from 'react-omnitech-api'
import {
  CustomLables,
  Link,
  LoadingSpinner,
  LoadingDots,
  PriceList,
  Rating,
  SocialMedia,
  InputQuantity,
} from '../../../../ui'
import {
  combineClassNames,
  isNotNullOrUndefined,
  parseStockLevel,
} from '../../../../helpers'
import {
  useCustomerRankSettings,
} from '../../../../hook'
import ArrowFat from '../../../../assets/icons/icon_solid_arrow.svg'
import ProductAddons from '../product-addons'
import ProductCardAdd from '../product-card-add/product-card-add-view'
import ColorPopoverView from '../color-popover/color-popover-view'
import useStyles from './product-details-style'

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]')
}

const ProductDetailView = ({
  addonsTouched,
  addonsValue,
  addToCartInProgress,
  availableQuantity,
  currency,
  displayAddonsErrors,
  fetchCartForEditReady,
  fnbEnabled,
  initProductReady,
  isAddonsValid,
  isEdit,
  isReadOnly,
  onAddonsChange,
  onAddonsValidate,
  onAddToCart,
  onColorOptionSelect,
  onFavouriteChange,
  onProductQuantityChange,
  onSale,
  onSizeOptionSelect,
  originalPrice,
  product = {},
  productAddons,
  productAddonsLoading,
  productQuantity,
  productReady,
  reviewsEnabled,
  selectedColorOptionId,
  selectedSizeOptionId,
  sellPrice,
  shareImageUrl,
  shareUrl,
  siblings,
  stockLevel,
}) => {
  // hook
  const { t } = useTranslation()
  const { default: defaultRank } = useCustomerRankSettings()
  const {
    user,
  } = useUser()
  const styles = useStyles()

  const {
    title, colorOptions, sizeOptions, skus,
  } = product

  const colorOptionVariantType = _.get(product, 'colorOptionVariantType', {})
  const sizeOptionVariantType = _.get(product, 'sizeOptionVariantType', {})
  const selectedColorOption = _.find(colorOptions, { id: selectedColorOptionId }) || {}
  const selectedSku = _.find(skus, {
    colorOptionId: selectedColorOptionId,
    sizeOptionId: selectedSizeOptionId,
  }) || {}
  const haveSiblings = !_.isEmpty(siblings)
  const isOutOfStock = haveSiblings
    ? _.sum(
      _.flatMap(
        siblings,
        ({ skus: siblingSkus }) => _.map(
          siblingSkus,
          ({ stockLevel: siblingStockLevel }) => parseStockLevel(siblingStockLevel),
        ),
      ),
    ) <= 0
    : parseStockLevel(stockLevel) <= 0
  const reviewsCount = _.get(product, 'reviewsCount', 0)
  const sizeGuideUrl = _.get(selectedColorOption, 'meta.sizeGuideUrl')

  const currentRank = _.get(user, 'currentCustomerRank.code', defaultRank)
  const memberPrice = _.get(selectedSku || _.first(skus), `meta.memberPrice.${currentRank}`)
  const priceSuffix = !isEdit && haveSiblings && t('screens.product.price.up')

  // TODO Open guide
  // button above coment until we do this task
  // https://docs.google.com/spreadsheets/d/1rFB6Ojd2jqsipYoXc15K2J9wAqj_J12igXEHhVGGWZ8/edit?disco=AAAAKDw4wXs

  return (
    <div className={styles.productDetail}>

      {
        !initProductReady && (
          <>
            <div className={styles.productTitlePlaceholder} />
            <div className={styles.pricePlaceholder} />
            <div className={styles.ratingPlaceholder} />
            <div className={styles.colorsBox}>
              <div className={styles.variantNamePlaceholder} />
              <div className={styles.optionsContainer}>
                {
                  _.times(isEdit ? 1 : 9, (index) => (
                    <span key={`variantItemPlaceholder_${index}`} className={styles.colorOptionsItemPlaceholder} />
                  ))
                }
              </div>
            </div>
            <div className={styles.colorsBox}>
              <div className={styles.variantNamePlaceholder} />
              <div className={styles.optionsContainer}>
                {
                  _.times(isEdit ? 1 : 4, (index) => (
                    <span key={`variantItemPlaceholder_${index}`} className={styles.sizeOptionsItemPlaceholder} />
                  ))
                }
              </div>
            </div>
            <div className={styles.productAddPlaceholder} />
          </>
        )
      }
      {
        initProductReady && (
          <h1 className={styles.productTitle}>{title}</h1>
        )
      }
      {
        !productReady && initProductReady ? (
          // FL: Show price loader before second product api call done
          <div className={combineClassNames([styles.priceStyle, styles.priceLoadingDots])}>
            <LoadingDots />
          </div>
        ) : (
          <PriceList
            currency={currency}
            // Show price for member if rank price provided by sku.meta
            items={[
              {
                ...(isNotNullOrUndefined(memberPrice) && { label: t('screens.product.price.nonMember') }),
                sellPrice,
                originalPrice,
                suffix: priceSuffix,
              },
              ...isNotNullOrUndefined(memberPrice)
                ? [{
                  label: t('screens.product.price.member'),
                  sellPrice: memberPrice,
                  suffix: priceSuffix,
                }] : [],
            ]}
            className={styles.priceStyle}
          />
        )
      }
      <div>
        <CustomLables
          customLabels={_.get(selectedColorOption, 'activeCustomLabels', [])}
          stockLevel={stockLevel}
          frontendPosition="1"
        />
      </div>
      {
        initProductReady && reviewsEnabled && (
          <>
            <div className={styles.rateBox}>
              <Rating rate={_.get(product, 'reviewsAverageRating')} editing={false} />
            </div>
            <AnchorLink to="#reviews">
              <span className={styles.anchorReviewStyle}>
                {
                reviewsCount === 0
                  && t('screens.product.reviewsAverageRatingZero')
              }
              </span>
            </AnchorLink>
          </>
        )
      }
      {!colorOptionVariantType.hideVariant && (
        <div className={styles.colorsBox}>
          <h5>{colorOptionVariantType.name}</h5>
          <div className={styles.optionsContainer}>
            {!isEdit && _.map(colorOptions, (color) => (
              <span className={styles.optionsItem} key={`color-${color.id}`}>
                <ColorPopoverView
                  colorOption={color}
                  onClick={() => {
                    onColorOptionSelect(color.id)
                  }}
                  isSelected={color.id === selectedColorOptionId}
                />
              </span>
            ))}
            {isEdit && (
              <span className={styles.optionsItem}>
                <ColorPopoverView
                  colorOption={_.find(colorOptions, { id: selectedColorOptionId })}
                  isSelected
                />
              </span>
            )}
          </div>
        </div>
      )}
      {!sizeOptionVariantType.hideVariant && (
        <div className={styles.sizesBox}>
          <div className={styles.sizeTitle}>
            <h5>{sizeOptionVariantType.name}</h5>

            {sizeGuideUrl && (
              <Link
                to={sizeGuideUrl}
                className={styles.linkViewGuide}
              >
                <span>{t('screens.product.sizeGuide')}</span>
                <img src={ArrowFat} alt={t('screens.product.sizeGuide')} />
              </Link>
            )}
          </div>
          <div className={styles.optionsContainer}>
            {!isEdit && _.map(sizeOptions, (size) => {
              const sku = _.find(skus, {
                sizeOptionId: size.id,
                colorOptionId: selectedColorOptionId,
              })
              const isSkuOutOfStock = _.get(sku, 'stockLevel', 0) <= 0
              return (
                <span
                  className={combineClassNames([
                    styles.optionsItem,
                    styles.sizeStyle,
                    selectedSizeOptionId === size.id && styles.sizeSelectedStyle,
                    isSkuOutOfStock && styles.sizeDisabled,
                  ])}
                  key={`size-${size.id}`}
                  onClick={() => {
                    onSizeOptionSelect(size.id)
                  }}
                  onKeyDown={() => {
                    onSizeOptionSelect(size.id)
                  }}
                >
                  {size.name}
                </span>
              )
            })}
            {isEdit && (
              <span
                className={combineClassNames([
                  styles.optionsItem,
                  styles.sizeStyle,
                  styles.sizeSelectedStyle,
                  // styles.sizeDisabled,
                ])}
              >
                {_.get(_.find(sizeOptions, { id: selectedSizeOptionId }), 'name', '')}
              </span>
            )}
          </div>
        </div>
      )}
      {/* addons */}
      {
        productAddonsLoading && <LoadingSpinner />
      }
      {
        !_.isEmpty(productAddons) && (
          <div className={styles.productAddons}>
            <ProductAddons
              // ref={productAddonsRef}
              currency={currency}
              productAddons={productAddons}
              value={addonsValue}
              touched={addonsTouched}
              isEdit={isEdit}
              // disabled={addToCartInProgress || notAllowedToCheckout}
              disabled={addToCartInProgress || !fetchCartForEditReady}
              displayErrors={displayAddonsErrors}
              onChange={onAddonsChange}
              onValidate={onAddonsValidate}
            />
          </div>
        )
      }
      {
        !isReadOnly && fnbEnabled && (
          <div className={styles.productQuantity}>
            <InputQuantity
              onChange={onProductQuantityChange}
              value={productQuantity}
              min={1}
              max={availableQuantity}
            />
          </div>
        )
      }
      {
        initProductReady && (
          <ProductCardAdd
            addToCartInProgress={addToCartInProgress}
            favourite={_.get(_.find(colorOptions, { id: selectedColorOptionId }), 'favourite')}
            fnbEnabled={fnbEnabled}
            hideSizeVariant={sizeOptionVariantType.hideVariant}
            initProductReady={initProductReady}
            isAddonsValid={isAddonsValid}
            isEdit={isEdit}
            isOutOfStock={isOutOfStock}
            isReadOnly={isReadOnly}
            onAddToCart={onAddToCart}
            onFavouriteChange={onFavouriteChange}
            productReady={productReady}
            selectedColorOptionId={selectedColorOptionId}
            sellPrice={sellPrice}
            sizeOptions={sizeOptions}
            sku={selectedSku}
            t={t}
          />
        )
      }
      <SocialMedia
        scale="2"
        marginIcon="0 2rem 0 0"
        title={product.title}
        imageUrl={shareImageUrl}
        classN={styles.shareGroup}
        shareUrl={shareUrl}
      />
    </div>
  )
}

export default ProductDetailView
