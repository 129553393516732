/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button, CurrencyFormat,
  FavouriteButton,
  LoadingDots,
} from '../../../../ui'
import CartIcon from '../../../../assets/icons/icon_cart_white.svg'
import useStyles from './product-card-add-style'

const ProductCardAddView = ({
  addToCartInProgress,
  favourite,
  fnbEnabled,
  hideSizeVariant,
  isAddonsValid,
  isEdit,
  isOutOfStock,
  isReadOnly,
  onAddToCart,
  onFavouriteChange,
  productReady,
  selectedColorOptionId,
  sellPrice,
  sizeOptions,
  sku,
}) => {
  // hook
  const { t } = useTranslation()
  const noPrice = _.isNull(sellPrice)
  const canSkuAddToCart = !(isOutOfStock || _.isEmpty(sku) || noPrice || !isAddonsValid)
  const isAddToCartButtonDisabled = !canSkuAddToCart || addToCartInProgress || !productReady
  const size = _.find(sizeOptions, { id: sku.sizeOptionId })

  // get add to cart label
  const addToCartLabel = useMemo(() => {
    let addToCartButtonState = ''
    switch (true) {
      case isOutOfStock:
        addToCartButtonState = 'outOfStock'
        break
      case _.isEmpty(size):
        addToCartButtonState = 'select'
        break
      case !isAddonsValid:
        addToCartButtonState = 'selectAddons'
        break
      case noPrice:
        addToCartButtonState = 'unavailable'
        break
      case !productReady:
      case addToCartInProgress:
        addToCartButtonState = 'loading'
        break
      case isEdit:
        addToCartButtonState = 'update'
        break
      default:
        break
    }
    return t('screens.product.cart.add', { context: addToCartButtonState })
  }, [
    addToCartInProgress,
    isAddonsValid,
    isEdit,
    isOutOfStock,
    noPrice,
    size,
    t,
  ])

  const styles = useStyles()

  const handleFavouriteChange = (data) => {
    if (!_.isFunction(onFavouriteChange)) return
    onFavouriteChange({ colorOptionId: selectedColorOptionId, ...data })
  }

  return (
    <div className={styles.cardAdd}>
      <div className={styles.details}>
        {
          !hideSizeVariant && (
            <dl className={styles.line}>
              <dt className={styles.label}>{t('screens.product.cart.size')}</dt>
              <dd className={styles.value}>{_.get(sku, 'meta.detailedSize', _.get(size, 'name', '-'))}</dd>
            </dl>
          )
        }
        <dl className={styles.line}>
          <dt className={styles.label}>{t('screens.product.cart.code')}</dt>
          <dd className={styles.value}>{_.get(sku, 'code', '-')}</dd>
        </dl>
        <dl className={styles.line}>
          <dt className={styles.label}>{t('screens.product.cart.availability')}</dt>
          <dd className={styles.value}>
            {
              productReady ? (
                t(`screens.product.cart.${isOutOfStock ? 'outOfStock' : 'inStock'}`)
              ) : (
                <LoadingDots className={styles.loadingDots} />
              )
            }
          </dd>
        </dl>
        {
          !fnbEnabled && (
            <dl className={styles.line}>
              <dt className={styles.label}>{t('screens.product.cart.price')}</dt>
              <dd className={styles.value}>
                {
                  !productReady && <LoadingDots className={styles.loadingDots} />
                }
                {
                  productReady && (
                    noPrice
                      ? '-'
                      : (
                        <CurrencyFormat
                          className={styles.price}
                          value={sellPrice}
                        />
                      ))
                }
              </dd>
            </dl>
          )
        }
      </div>
      {
        !isReadOnly && (
          <div className={styles.buttonContainer}>
            <Button
              dark
              iconImage={CartIcon}
              className={styles.addToCartButton}
              iconClassName={styles.addToCartButtonIcon}
              disabled={isAddToCartButtonDisabled}
              classNameDisabled={styles.addToCartButtonDisabled}
              text={addToCartLabel}
              onClick={() => _.isFunction(onAddToCart) && onAddToCart({ skuId: _.get(sku, 'id') })}
            />
          </div>
        )
      }
      <div className={styles.buttonContainer}>
        <FavouriteButton
          iconButton
          label={t('screens.product.cart.favourites')}
          colorOptionID={selectedColorOptionId}
          favourite={favourite}
          onChange={handleFavouriteChange}
        />
      </div>
    </div>
  )
}

export default ProductCardAddView
