import _ from 'lodash'
import React from 'react'
import useStyles from './specifications-card-style'
import {
  Title,
} from '../../../../ui'

const SpecificationsView = ({ t, detailAttributes }) => {
  const styles = useStyles()
  return (
    <div className={styles.specifications}>
      <Title
        margin="0 0 1.2rem"
        text={t('screens.product.specifications.title')}
        classTitle={styles.titleStyle}
      />
      <div className={styles.table}>
        {
          _.map(detailAttributes, ({ label, value }, index) => (
            <dl key={`specification-line-${index}`} className={styles.line}>
              <dt className={styles.label}>{label}</dt>
              <dd className={styles.value}>{value}</dd>
            </dl>
          ))
        }
      </div>
    </div>
  )
}

export default SpecificationsView
