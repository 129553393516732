import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => {
  const cell = {
    padding: '2rem',
    background: theme.colors.background,
    fontSize: '1.4rem',
    textTransform: 'capitalize',
    border: `1px solid ${theme.colors.bgMedium}`,
    margin: '-1px',
  }
  return ({
    specifications: () => ({
      marginTop: `${theme.doubleGap}rem`,
      maxWidth: '77rem',
      display: 'flex',
      flexDirection: 'column',
    }),
    table: {
      marginTop: `${theme.gap}rem`,
      // display: 'grid',
      // gridTemplateColumns: '1fr 2fr',
      // gridTemplateRows: 'repeat(3, 1fr)',
      // gridColumnGap: 1,
      // gridRowGap: 1,
      textAlign: 'left',
      background: theme.colors.bgMedium,
      border: `1px solid ${theme.colors.bgMedium}`,
    },
    line: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'stretch',
      margin: 0,
    },
    label: {
      extend: cell,
      width: '39%',
      maxWidth: '27rem',
    },
    value: {
      extend: cell,
      flex: 1,
      background: theme.colors.bgLight,
    },
    titleStyle: {
      fontSize: '2.4rem',
      lineHeight: '2.8rem',
      [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
        fontSize: '3.6rem',
        lineHeight: '4rem',
      },
    },
  })
})

export default useStyles
