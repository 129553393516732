import React from 'react'
import { Router } from '@reach/router'
import _ from 'lodash'
import ProductScreen from '../screens/product'

const ProductsPage = (props) => (
  <Router>
    <ProductScreen {...props} path={`${_.get(props, 'pageContext.locale')}/product/`} />
    <ProductScreen {...props} path={`${_.get(props, 'pageContext.locale')}/product/:id`} />
    <ProductScreen {...props} path={`${_.get(props, 'pageContext.locale')}/product/:slug/:id`} />
    <ProductScreen {...props} path={`${_.get(props, 'pageContext.locale')}/:slug/pdp/:id`} />
  </Router>
)

export default ProductsPage
