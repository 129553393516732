import _ from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './product-style'
import {
  Layout,
  Breadcrumb,
  ModalCompare,
  SEO, ContentGroup,
} from '../../ui'
import {
  Specifications,
  MoreInfo,
  Suggestions,
  Reviews,
  ProductImages,
  ProductDetails,
} from './components'

function ProductView({
  addonsTouched,
  addonsValue,
  addToCartInProgress,
  availableQuantity,
  breadcrumb,
  compareData,
  currency,
  enableComparisonEcom,
  fetchCartForEditReady,
  fnbEnabled,
  goToCompareProducts,
  hasMoreReview,
  initProductReady,
  isAddonsValid,
  isEdit,
  isFavourited,
  isOpen,
  isReadOnly,
  maxNumberComparisonEcom,
  onAddonsChange,
  onAddonsValidate,
  onAddToCart,
  onAddToCompare,
  onClearCompare,
  onClickTrackEvent,
  onColorChange,
  onCreateReview,
  onFavouriteChange,
  onNextReview,
  onProductQuantityChange,
  onSale,
  onSizeChange,
  onUpdateFavourite,
  onViewSizeColorOptions,
  originalPrice,
  pdpPageContentGroupCode,
  pdpPageContentGroupTemplate,
  product,
  productAddons,
  productAddonsLoading,
  productQuantity,
  productReady,
  relatedProducts,
  relatedProductsReady,
  reviews,
  reviewsEnabled,
  reviewsPagination,
  reviewsReady,
  selectedColorOptionId,
  selectedSizeOptionId,
  sellPrice,
  seoDescription,
  seoLinks,
  seoMeta,
  seoTitle,
  shareImageUrl,
  shareUrl,
  siblings,
  stockLevel,
  user,
}) {
  const styles = useStyles()
  const { t } = useTranslation()

  const {
    colorOptions,
  } = product

  const selectedColorOption = _.find(colorOptions, { id: selectedColorOptionId })
  const detailAttributes = _.get(product, 'productDetailAttributes', [])
  // to reset slider to 0 there is not another way than rerender the component again
  // https://stackoverflow.com/questions/40794071/react-slick-reinit

  const ImagesCarousel = useMemo(() => {
    const images = () => (
      <div>
        <ProductImages
          pictures={_.map(_.get(selectedColorOption, 'images', []), 'versions')}
          loading={!initProductReady}
        />
      </div>
    )
    return images
  }, [selectedColorOptionId, initProductReady])

  return (
    <Layout>
      {/* passing page specific data for SEO */}
      {
        !_.isEmpty(seoTitle)
        && (
        <SEO
          description={seoDescription}
          title={seoTitle}
          meta={seoMeta}
          link={seoLinks}
          img={_.get(product, 'colorOptions[0].images[0].versions.webMedium')}
        />
        )
      }
      <Breadcrumb classN={styles.breadcrumb} links={breadcrumb} />
      <div className={styles.containerFluid}>
        <div className={styles.container}>
          <div className={styles.boxProduct}>
            <div className={styles.boxImage}>
              <ImagesCarousel />
            </div>
            <div className={styles.boxDetails}>
              <ProductDetails
                addonsValue={addonsValue}
                addonsTouched={addonsTouched}
                addToCartInProgress={addToCartInProgress}
                availableQuantity={availableQuantity}
                currency={currency}
                fetchCartForEditReady={fetchCartForEditReady}
                fnbEnabled={fnbEnabled}
                initProductReady={initProductReady}
                isAddonsValid={isAddonsValid}
                isEdit={isEdit}
                isFavourited={isFavourited}
                isReadOnly={isReadOnly}
                onAddonsChange={onAddonsChange}
                onAddonsValidate={onAddonsValidate}
                onAddToCart={onAddToCart}
                onColorOptionSelect={onColorChange}
                onFavouriteChange={onFavouriteChange}
                onProductQuantityChange={onProductQuantityChange}
                onSale={onSale}
                onSizeOptionSelect={onSizeChange}
                onUpdateFavourite={onUpdateFavourite}
                onViewSizeColorOptions={onViewSizeColorOptions}
                originalPrice={originalPrice}
                product={product}
                productAddons={productAddons}
                productAddonsLoading={productAddonsLoading}
                productQuantity={productQuantity}
                productReady={productReady}
                reviewsEnabled={reviewsEnabled}
                selectedColorOptionId={selectedColorOptionId}
                selectedSizeOptionId={selectedSizeOptionId}
                sellPrice={sellPrice}
                shareImageUrl={shareImageUrl}
                shareUrl={shareUrl}
                siblings={siblings}
                stockLevel={stockLevel}
                t={t}
              />
            </div>
          </div>
          { !_.isEmpty(detailAttributes) && (
            <Specifications t={t} detailAttributes={detailAttributes} />
          )}
          {/* <InfoBox title={t('screens.product.description')} /> */}
          { _.get(product, 'detailsPlainText') && (
            <MoreInfo t={t} htmlContent={_.get(product, 'details')} />
          )}
          <div className={styles.container}>
            <ContentGroup
              code={pdpPageContentGroupCode}
              template={pdpPageContentGroupTemplate}
            />
          </div>
        </div>
        {relatedProductsReady && !_.isEmpty(relatedProducts) && (
          <Suggestions
            relatedProducts={relatedProducts}
            t={t}
            onClickTrackEvent={onClickTrackEvent}
          />
        )}
        {
          reviewsReady && reviewsEnabled && (
            <Reviews
              t={t}
              reviews={reviews}
              reviewsPagination={reviewsPagination}
              user={user}
              onNextReview={onNextReview}
              onCreateReview={onCreateReview}
              hasMoreReview={hasMoreReview}
            />
          )
        }
      </div>
      {
        enableComparisonEcom
        && (
        <ModalCompare
          compareData={compareData}
          onAddToCompare={onAddToCompare}
          goToCompareProducts={goToCompareProducts}
          t={t}
          isOpen={isOpen}
          onClearCompare={onClearCompare}
          maxNumberComparisonEcom={maxNumberComparisonEcom}
        />
        )
      }
    </Layout>
  )
}

export default ProductView
