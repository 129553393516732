import _ from 'lodash'
import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useImperativeHandle,
  forwardRef,
} from 'react'
// import { Animated } from 'react-native'
import { useAuth, useUser } from 'react-omnitech-api'
import { useTranslation } from 'react-i18next'
import ProductAddonsView from './product-addons-view'
import {
  isNotNullOrUndefined,
  isNullOrUndefined,
  parseStockLevel,
  // isSkuAllowToCheckout,
} from '../../../../helpers'
import {
  useCustomerRankSettings,
} from '../../../../hook'
// import Locale from '../../../services/locale'

const ProductAddonsController = (props) => {
  const {
    productAddons = [],
    value = [],
    touched = [],
    onChange = () => {},
    onValidate = () => {},
    displayErrors,
    isEdit,
    isReadOnly,
    ...otherProps
  } = props

  // hooks
  const { t, i18n } = useTranslation()
  const { auth } = useAuth()
  const { user } = useUser()
  const { default: defaultRank } = useCustomerRankSettings()

  const currentLocale = i18n.language
  const customerRankCode = _.get(user, 'currentCustomerRank.code', '')
  // orderMethod: _.get(state, 'orderMethod.orderMethod', {}),
  const isLoggedIn = !_.isEmpty(_.get(auth, 'userId'))

  const addonSkuPrice = useCallback(({ sku, display = 'increment' }) => {
    const rankCode = _.camelCase(isLoggedIn ? customerRankCode : defaultRank)
    const sellPrice = _.get(sku, 'sellPrice')
    const memberPrice = _.get(sku, `meta.memberPrice.${rankCode}`)
    if (!sellPrice || sellPrice === '0.0' || display === 'hidden') return false
    return [
      {
        sellPrice,
        prefix: display !== 'fixed' && '+',
        ...memberPrice && {
          label: t('screens.product.price.nonMember'),
        },
      },
      memberPrice && {
        label: t('screens.product.price.member'),
        sellPrice: memberPrice,
        prefix: display !== 'fixed' && '+',
      },
    ]
  }, [customerRankCode, defaultRank, isLoggedIn, t])

  const selectionType = ({ minimumSkuSelect, maximumPerSkuQuantity, maximumSkuSelect }) => {
    switch (true) {
      case (maximumPerSkuQuantity > 1):
        return 'quantity'
      case (maximumSkuSelect > 1 || minimumSkuSelect < 1):
        return 'multiple'
      default:
        return 'single'
    }
  }

  const sections = useMemo(() => {
    const updatedSections = _.reduce(productAddons, (result, _addon, _addonIndex) => {
      const createSection = (addon, parents = []) => {
        const {
          id,
          addons,
          meta,
          minimumPerSkuQuantity,
          minimumSkuSelect,
          minimumTotalQuantity,
          maximumPerSkuQuantity,
          maximumSkuSelect,
          parentProductAddonId,
        } = addon

        const quantityIndex = _.get(_.last(parents), 'quantityIndex', 0)

        if (_.get(meta, 'sectionHeader', false)) {
          // backward compatible
          const headerProduct = _.first(addons)
          const nextAddon = _.nth(productAddons, _addonIndex + 1)
          result.push({
            // ...addon,
            title: _.get(headerProduct, 'title'),
            description: _.get(headerProduct, 'detailsPlainText'),
            required: _.sum([
              _.get(nextAddon, 'minimumSkuSelect', 0),
              _.get(nextAddon, 'minimumPerSkuQuantity', 0),
              _.get(nextAddon, 'minimumTotalQuantity', 0),
            ]) > 0,
          })
          return result
        }

        const type = selectionType({ minimumSkuSelect, maximumPerSkuQuantity, maximumSkuSelect })
        const firstAddon = _.first(addons)
        const commonOptionsProps = {
          productAddonId: id,
          quantityIndex,
        }
        const findValue = ({
          addonId: _addonId,
          productAddonId: _productAddonId,
          quantityIndex: _quantityIndex,
          skuId: _skuId,
        }) => _.filter(value, (item) => (
          _.isEqual(_.get(item, 'addonId'), _addonId)
            && _.isEqual(_.get(item, 'productAddonId'), _productAddonId)
            && _.isEqual(_.get(item, 'quantityIndex'), _quantityIndex)
            && _.isEqual(_.get(item, 'skuId'), _skuId)
            && (
              _.isEqual(_.get(_.last(_.get(item, 'parents')), 'productAddonId'), parentProductAddonId)
              || (
                isNullOrUndefined(_.get(_.last(_.get(item, 'parents')), 'productAddonId'))
                && isNullOrUndefined(parentProductAddonId)
              )
            )
        ))
        const options = _.size(addons) === 1 && _.size(_.get(firstAddon, 'skus', [])) > 1
          ? _.map(_.get(firstAddon, 'skus', []), (sku) => {
            const optionValues = findValue({
              addonId: _.get(firstAddon, 'id'),
              productAddonId: id,
              quantityIndex,
              skuId: _.get(sku, 'id'),
            })
            const quantity = _.sumBy(
              optionValues,
              'quantity',
            )
            const stockLevel = parseStockLevel(_.get(sku, 'stockLevel'))
            return {
              title: _.get(_.find(_.get(firstAddon, 'colorOptions', []), { id: _.get(sku, 'colorOptionId') }), 'name'),
              id: _.get(sku, 'id'),
              skuCode: _.get(sku, 'code'),
              addonId: _.get(firstAddon, 'id'),
              quantity,
              // disabled: isSkuAllowToCheckout(sku),
              price: addonSkuPrice({ sku, display: _.get(firstAddon, 'meta.price', _.isNull(id) ? 'fixed' : 'increment') }),
              max: _.min([maximumPerSkuQuantity, stockLevel]),
              stockLevel,
              ...commonOptionsProps,
            }
          })
          : _.flatMap(addons, ({
            id: addonId,
            skus,
            colorOptions,
            meta: addonMeta,
            title: groupTitle,
          }) => (
            _.map(skus, (sku) => {
              const {
                id: skuId,
                code: skuCode,
                colorOptionId,
                stockLevel,
              } = sku
              const stockLevelInNumber = parseStockLevel(stockLevel)
              const optionValues = findValue({
                addonId,
                productAddonId: id,
                quantityIndex,
                skuId,
              })
              const quantity = _.sumBy(
                optionValues,
                'quantity',
              )
              return ({
                id: skuId,
                skuCode,
                title: _.get(_.find(colorOptions, { id: colorOptionId }), 'name'),
                addonId,
                quantity,
                // disabled: isSkuAllowToCheckout(sku),
                price: addonSkuPrice({ sku, display: _.get(addonMeta, 'price', _.isNull(id) ? 'fixed' : 'increment') }),
                max: _.min([maximumPerSkuQuantity, stockLevelInNumber]),
                stockLevel: stockLevelInNumber,
                ...commonOptionsProps,
              })
            })
          ))

        const required = _.sum([minimumSkuSelect, minimumPerSkuQuantity, minimumTotalQuantity]) > 0
        const error = displayErrors && (
          _.sumBy(options, 'quantity') < minimumTotalQuantity
                        || _.some(options, ({ quantity }) => quantity < minimumPerSkuQuantity)
                        || _.size(
                          _.filter(options, ({ quantity }) => quantity > 0),
                        ) < minimumSkuSelect
        )

        result.push({
          ...addon,
          title: _.get(meta, `addonHeader.title.${_.camelCase(currentLocale)}`),
          description: _.get(meta, `addonHeader.description.${_.camelCase(currentLocale)}`),
          required,
          error,
          type,
          options,
          parents,
        })
        // if selected and have nested addons
        _.each(options, ({
          quantity,
          addonId,
          id: selectedSkuId,
          productAddonId,
        }) => {
          const selectedAddon = _.find(addons, { id: addonId })
          _.times(quantity, (qtyIdx) => {
            _.each(
              _.get(selectedAddon, 'productAddons', []),
              (pa) => {
                const nextParents = _.compact([
                  ...parents,
                  {
                    skuId: selectedSkuId,
                    addonId,
                    quantityIndex: qtyIdx,
                    productAddonId: id,
                  },
                ])
                createSection({ ...pa, parentProductAddonId: productAddonId }, nextParents)
              },
            )
          })
        })
      }
      createSection(_addon)
      return result
    }, [])

    const requiredSections = _.filter(
      updatedSections,
      ({
        minimumSkuSelect,
        minimumPerSkuQuantity,
        minimumTotalQuantity,
      }) => (minimumSkuSelect + minimumPerSkuQuantity + minimumTotalQuantity) > 0,
    )
    const isAllRequriedAddonReady = _.isUndefined(
      _.find(requiredSections, ({
        minimumSkuSelect,
        minimumPerSkuQuantity,
        minimumTotalQuantity,
        id,
      }) => {
        const sectionValues = _.filter(value, { productAddonId: id })
        return _.sumBy(sectionValues, 'quantity') < minimumTotalQuantity
            || _.some(sectionValues, ({ quantity }) => quantity < minimumPerSkuQuantity)
            || _.size(_.filter(sectionValues, ({ quantity }) => quantity > 0)) < minimumSkuSelect
      }),
    )
    onValidate(isAllRequriedAddonReady)
    return updatedSections
  }, [productAddons, onValidate, displayErrors, currentLocale, value, addonSkuPrice])

  // useImperativeHandle(ref, () => ({
  //   reset: () => {
  //     // setChanges([])
  //   }
  // }))

  const onSectionChange = useCallback((val) => {
    const {
      id: productAddonId,
      options = [],
      parents = [],
      type,
      value: updatedValue,
    } = val

    const quantityIndex = _.get(_.last(parents), 'quantityIndex', 0)
    const otherSectionsValue = _.reject(
      value,
      ({
        productAddonId: origProductAddonId,
        quantityIndex: origQuantityIndex,
        parents: origParents,
      }) => (
        _.isEqual(origProductAddonId, productAddonId)
        && _.isEqual(origQuantityIndex, quantityIndex)
        && (
          _.isEqual(_.get(_.last(origParents), 'productAddonId'), _.get(_.last(parents), 'productAddonId'))
            || (
              isNullOrUndefined(_.get(_.last(origParents), 'productAddonId'))
              && isNullOrUndefined(_.get(_.last(parents), 'productAddonId'))
            )
        )
      ),
    )

    const newValue = _.flatMap(_.castArray(updatedValue), (updatedValueItem) => {
      if (type === 'quantity') {
        return _.map(updatedValueItem, (quantity, selectedQtyOptionId) => {
          const selectedQtyOptionIdInNumber = _.toNumber(selectedQtyOptionId)
          const selectedQtyOption = _.find(options, { id: selectedQtyOptionIdInNumber })
          return {
            ..._.pick(selectedQtyOption, [
              'addonId',
              'productAddonId',
              'skuCode',
              'title',
            ]),
            quantity,
            quantityIndex,
            skuId: selectedQtyOptionIdInNumber,
            stockLevel: _.get(selectedQtyOption, 'stockLevel'),
            parents,
          }
        })
        // return _.map(options, (option))
      }
      const selectedOption = _.find(options, { id: updatedValueItem })
      return {
        ..._.pick(selectedOption, [
          'addonId',
          'productAddonId',
          'skuCode',
          'title',
        ]),
        quantity: 1,
        quantityIndex,
        skuId: updatedValueItem,
        stockLevel: _.get(selectedOption, 'stockLevel'),
        parents,
      }
    })

    const removedOptions = _.reduce(
      options,
      (result, option) => {
        const {
          id: optionSkuId,
          quantity,
        } = option
        const newValueQty = _.get(_.find(newValue, { skuId: optionSkuId }), 'quantity', 0)
        if (newValueQty < quantity) {
          const subtractedQty = quantity - newValueQty
          result.push({
            ...option,
            quantity: subtractedQty,
            quantityIndex: quantity - subtractedQty,
          })
        }
        return result
      },
      [],
    )
    const removedChildrenValue = _.filter(
      otherSectionsValue,
      ({ parents: origParents = [], quantityIndex: origQuantityIndex }) => {
        const optionsHaveMatchedParent = _.filter(
          removedOptions,
          ({ id: skuId, addonId, productAddonId: removedOptionProductAddonId }) => (
            _.some(origParents, { skuId, addonId, productAddonId: removedOptionProductAddonId })
          ),
        )
        return !_.some(optionsHaveMatchedParent, { quantityIndex: origQuantityIndex })
      },
    )
    const mergedValue = [
      ...removedChildrenValue,
      ...newValue,
    ]

    // delete productAddonId from touched list if options have removed
    const origTouched = _.difference(touched, _.map(removedOptions, 'productAddonId'))
    // combine current productAddonId to touched list
    const updatedTouched = _.uniq([
      ...origTouched,
      productAddonId,
    ])

    onChange(
      mergedValue,
      updatedTouched,
    )
  }, [onChange, touched, value])

  useEffect(() => {
    if (isEdit || isReadOnly) return
    _.each(sections, (section) => {
      if (_.includes(touched, _.get(section, 'id'))) return
      const defaultSelectedSkuCodes = _.get(section, 'meta.defaultSelectedSkuCodes', [])
      const options = _.get(section, 'options', [])
      const preSelectedOptions = _.reject(
        _.map(defaultSelectedSkuCodes, ({ code: skuCode, quantity }) => (
          {
            ..._.find(options, { skuCode }),
            quantity,
          }
        )),
        ({ skuCode }) => _.isNil(skuCode),
      )
      if (_.isEmpty(preSelectedOptions)) return

      const sectionValue = _.reduce(preSelectedOptions, (result, option) => {
        switch (_.get(section, 'type')) {
          case 'quantity':
            result.push({
              [_.get(option, 'id')]: _.get(option, 'quantity'),
            })
            break;
          default:
            result.push(_.get(option, 'id'))
            break;
        }
        return result
      }, [])
      onSectionChange({
        ...section,
        value: sectionValue,
      })
    })
  }, [isEdit, isReadOnly, onSectionChange, sections, touched])

  const viewProps = {
    // changes,
    currentLocale,
    displayErrors,
    isReadOnly,
    onSectionChange,
    sections,
    // onHeaderLayout,
    // onItemLayout: handleItemLayout,
    // initLayouts,
    ...otherProps,
  }

  return (
    <ProductAddonsView {...viewProps} />
  )
}

export default ProductAddonsController
