import _ from 'lodash'
import React, { useCallback } from 'react'

import ProductAddonSection from '../product-addon-section'
// import styles from './product-addons-styles'

const Section = ({
  item,
  onChange,
  ...rest
}) => {
  const handleChange = useCallback((value) => {
    if (_.isFunction(onChange)) onChange({ value, ...item })
  }, [item, onChange])
  return (
    <ProductAddonSection
      {...item}
      onChange={handleChange}
      {...rest}
    />
  )
}

const ProductAddonsView = (props) => {
  const {
    currentLocale,
    currency,
    disabled,
    displayErrors,
    initLayouts,
    ListHeaderComponent,
    ListFooterComponent,
    onItemLayout,
    onHeaderLayout,
    onSectionChange,
    sections,
    loading,
  } = props

  return (
    <>
      {
        _.map(sections, (item) => (
          <Section
            key={`section_${_.get(item, 'id')}_${_.get(_.last(_.get(item, 'parents', [])), 'quantityIndex', '0')}`}
            item={item}
            onChange={onSectionChange}
            currency={currency}
            disabled={disabled}
          />
        ))
      }
    </>
  )
}

export default ProductAddonsView
