/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import _ from 'lodash'
import React, { useState } from 'react'
import Popover, { ArrowContainer } from 'react-tiny-popover';
import { useTheme } from 'react-jss'
import useStyles from './color-popover-style'
import { combineClassNames } from '../../../../helpers'

const ColorPopoverView = ({ colorOption = {}, onClick, isSelected }) => {
  const {
    id,
    name,
  } = colorOption
  const {
    colorStyle,
    colorSelectedStyle,
    popoverStyle,
    colorSwatchImage,
  } = useStyles()

  // Color Swatch
  const colorHex = _.replace(_.get(colorOption, 'hexValue', '#ffffff'), /^#?NO_HEX$/, '#ffffff')
  const isColorHexTooLight = /^#(f[a-f]){3}/.test(colorHex.toLowerCase())
  const svgStroke = isColorHexTooLight ? "stroke='%23d7d7d7' stroke-width='1'" : "stroke='none'"
  const colorSwatchSrc = _.get(colorOption, 'swatch.versions.webThumbPortrait', `data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 52'%3e%3crect width='48' height='52' x='0' y='0' fill='${encodeURIComponent(colorHex)}' ${svgStroke} /%3e%3c/svg%3e`)

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const theme = useTheme()
  const handlePopover = (idActivated) => {
    if (idActivated === id) setIsPopoverOpen(!isPopoverOpen)
  }
  return (
    <Popover
      isOpen={isPopoverOpen}
      position={['bottom']}
      content={({ position, targetRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          targetRect={targetRect}
          popoverRect={popoverRect}
          arrowColor={theme.colors.text}
          arrowSize={10}
          arrowStyle={{ opacity: 0.7 }}
        >
          <div
            role="button"
            className={popoverStyle}
            style={{ opacity: 0.7 }}
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            <span>{name}</span>
          </div>
        </ArrowContainer>
      )}
    >
      {(ref) => (
        <div
          role="button"
          ref={ref}
          className={combineClassNames([colorStyle, isSelected && colorSelectedStyle])}
          onMouseEnter={() => handlePopover(id)}
          onMouseOut={() => setIsPopoverOpen(false)}
          onClick={onClick}
          // style={{ backgroundColor: hexValue }}
        >
          <img className={colorSwatchImage} src={colorSwatchSrc} alt={name} />
        </div>
      )}
    </Popover>
  )
}

export default ColorPopoverView
