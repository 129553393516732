import { createUseStyles } from 'react-jss'
import hexToRgba from '../../../../helpers/hex-to-rgba'

const useStyles = createUseStyles((theme) => ({
  '@keyframes loading': {
    from: {
      transform: 'translateX(-100%)',
    },
    to: {
      transform: 'translateX(100%)',
    },
  },
  placeholder: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: '#eee',
    borderRadius: '1rem',
    userSelect: 'none',
    pointerEvent: 'none',
    '-webkit-touch-callout': 'none',
    '&::after': {
      content: '""',
      display: 'block',
      opacity: 0.25,
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      background: `linear-gradient(to right, ${hexToRgba(theme.colors.background, 0)} 0%,${hexToRgba(theme.colors.background, 1)} 50%,${hexToRgba(theme.colors.background, 0)} 100%)`,
      transform: 'translateX(-100%)',
      animationName: '$loading',
      animationDuration: '2s',
      animationTimingFunction: 'ease',
      animationIterationCount: 'infinite',
    },
  },
  productDetail: {
    padding: '3rem 0 0',
    textAlign: 'left',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      padding: 0,
    },
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      padding: '0 3rem',
    },
    '& h1': {
      fontSize: '3rem',
      marginBottom: '0.6rem',
    },
    '& a': {
      textDecoration: 'none',
    },
  },
  productTitle: {
    fontSize: '3rem',
    fontWeight: 500,
  },
  productTitlePlaceholder: {
    width: '100%',
    height: '3.75rem',
    marginBottom: '0.6rem',
    extend: 'placeholder',
  },
  priceStyle: {
    marginBottom: '0.6rem',
    fontSize: '3rem',
  },
  pricePlaceholder: {
    width: '13rem',
    height: '3.75rem',
    marginBottom: '0.6rem',
    extend: 'placeholder',
  },
  priceLoadingDots: {
    paddingTop: '2.75rem',
    paddingLeft: '0.3rem',
  },
  ratingPlaceholder: {
    width: '12rem',
    height: '2rem',
    marginBottom: '0.6rem',
    extend: 'placeholder',
  },
  productAddPlaceholder: {
    marginTop: `${theme.gap}rem`,
    marginBottom: '2.2rem',
    maxWidth: '42rem',
    width: '100%',
    height: '25rem',
    extend: 'placeholder',
  },
  originalPriceStyle: {
    fontSize: '1.5rem',
    fontWeight: 400,
    textDecoration: 'line-through',
    color: theme.colors.bgDark,
    marginRight: '0.2rem',
  },
  sellPriceStyle: {
    fontSize: '3rem',
    fontWeight: 400,
  },
  onSaleSellPriceStyle: {
    color: theme.colors.alert,
  },
  anchorReviewStyle: {
    fontSize: '1.4rem',
    color: theme.colors.primary,
    margin: 0,
    lineHeight: 1.12,
  },
  rateBox: {
    marginTop: '0.2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    '& div': {
      marginRight: '1rem',
      [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
        marginRight: '1rem',
      },
    },
  },
  colorsBox: {
    '& h5': {
      marginTop: `${theme.gap}rem`,
      textTransform: 'uppercase',
      fontSize: '1.5rem',
      fontWeight: 600,
      marginBottom: '1rem',
    },
  },
  colorStyle: {
    height: '5.2rem',
    cursor: 'pointer',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    '&.select': {
      borderColor: theme.colors.secondary,
    },
  },
  sizesBox: {
    '& h5': {
      textTransform: 'uppercase',
      fontSize: '1.5rem',
      fontWeight: 600,
      marginBottom: '1rem',
    },
  },
  optionsItem: {
    display: 'inline-block',
    marginRight: '1.5rem',
    marginBottom: '1.6rem',
    minWidth: '4.8rem',
  },
  sizeStyle: {
    minHeight: '3.6rem',
    minWidth: '5.1rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    fontSize: '1.4rem',
    fontWeight: 600,
    textAlign: 'center',
    lineHeight: 2.5,
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.colors.bgDark,
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      background: theme.colors.bgDark,
      borderColor: theme.colors.bgDark,
    },
  },
  sizeSelectedStyle: {
    borderColor: theme.colors.secondary,
    background: hexToRgba(theme.colors.secondary, 0.5),
    '&:hover': {
      borderColor: theme.colors.secondary,
      background: hexToRgba(theme.colors.secondary, 0.5),
    },
  },
  sizeDisabled: {
    backgroundColor: hexToRgba(theme.colors.disabled, 0.2),
    '&:hover': {
      // background: theme.colors.background,
      borderColor: theme.colors.bgMediumDark,
    },
  },
  optionsContainer: {
    marginRight: '-2rem',
    marginBottom: '-1.5rem',
  },
  variantNamePlaceholder: {
    width: '8rem',
    height: '2rem',
    marginTop: '3rem',
    marginBottom: '1rem',
    extend: 'placeholder',
    borderRadius: '0.5rem',
  },
  colorOptionsItemPlaceholder: {
    extend: ['optionsItem', 'placeholder'],
    height: '5.2rem',
  },
  sizeOptionsItemPlaceholder: {
    extend: ['optionsItem', 'placeholder'],
    height: '3.7rem',
  },
  productAddons: {
    marginTop: '2.5rem',
    maxWidth: '42rem',
  },
  productQuantity: {
    marginTop: '1.25rem',
    paddingTop: '1.25rem',
    paddingBottom: '1.25rem',
    maxWidth: '42rem',
    borderTop: `1px solid ${theme.colors.bgMediumDark}`,
    borderBottom: `1px solid ${theme.colors.bgMediumDark}`,
  },
  guideLink: {
    fontSize: '1.4rem',
    fontWeight: 600,
    textAlign: 'left',
    marginTop: '2rem',
    marginBottom: 0,
    padding: '1rem 2.5rem 1rem 0',
    border: 'none',
    minHeight: 0,
    width: 'auto',
  },
  guideLinkIcon: {
    left: 'auto',
    right: 0,
  },
  shareGroup: {
    paddingTop: '0.2rem',
    '& h6': {
      fontSize: '1.4rem',
      marginBottom: 0,
      fontWeight: 600,
    },
  },
  sizeTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
    marginTop: '2.5rem',
    '& h5': {
      margin: 0,
    },
  },
  linkViewGuide: {
    minHeight: 'auto',
    width: 'auto',
    paddingRight: 5,
    textDecoration: 'none',
    '&:hover': {
      color: theme.colors.secondary,
    },
    '& span': {
      color: theme.colors.text,
      fontSize: '1,5rem',
      fontWeight: 600,
    },
    '& img': {
      margin: 0,
      marginLeft: '1rem',
    },
  },
}))

export default useStyles
