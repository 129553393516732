import { createUseStyles } from 'react-jss'
import hexToRgba from '../../../../helpers/hex-to-rgba'
import ratioCalc from '../../../../helpers/ratioImage'

const useStyles = createUseStyles((theme) => ({
  '@keyframes loading': {
    from: {
      transform: 'translateX(-100%)',
    },
    to: {
      transform: 'translateX(100%)',
    },
  },
  loadingPlaceholder: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: '#eee',
    width: '100%',
    height: 0,
    paddingBottom: ratioCalc(theme.config.ratioPdp),
    borderRadius: '1rem',
    userSelect: 'none',
    pointerEvent: 'none',
    '-webkit-touch-callout': 'none',
    '&::after': {
      content: '""',
      display: 'block',
      opacity: 0.25,
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      background: `linear-gradient(to right, ${hexToRgba(theme.colors.background, 0)} 0%,${hexToRgba(theme.colors.background, 1)} 50%,${hexToRgba(theme.colors.background, 0)} 100%)`,
      transform: 'translateX(-100%)',
      animationName: '$loading',
      animationDuration: '2s',
      animationTimingFunction: 'ease',
      animationIterationCount: 'infinite',
    },
  },
  productImage: {
    width: '100%',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      width: 670,
    },
  },
  placeholder: {
    width: '100%',
    height: 0,
    paddingBottom: '117.5%',
    position: 'relative',
    backgroundColor: theme.colors.bgDark,
  },
  placeholderImage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '40%',
  },
  mainWindow: {
    position: 'relative',
    width: '100%',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      maxWidth: '67rem',
    },
    '& .slick-slider': {
      outline: 'none',

    },
    '& .slick-list': {
      [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
        maxWidth: '55vw',
        width: '100%',
        overflow: 'hidden',
      },
    },
    '& .magic-dots.slick-dots': {
      bottom: '3rem',
      '& li': {
        '& button:before': {
          fontSize: '1rem',
          opacity: 1,
          color: 'rgba(65,65,65,0.5)',
        },
        '&.small button:before': {
          fontSize: '0.6rem',
        },
        '&.slick-active button:before': {
          color: theme.colors.secondary,
        },
      },
    },
  },
  sliderItem: {
    maxHeight: '0',
    maxWidth: '67rem',
    margin: [0, 'auto'],
    paddingBottom: ratioCalc(theme.config.ratioPdp),
    overflow: 'hidden',
    display: 'flex !important',
    justifyContent: 'center',
    width: '100%',
  },
  imageZoom: ({ disableImageZoomMobile, disableImageZoomDesktop }) => ({
    display: !disableImageZoomMobile ? 'block' : 'none',
    width: '100%',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      display: !disableImageZoomDesktop ? 'block' : 'none',
    },
  }),
  imageWithoutZoom: ({ disableImageZoomMobile, disableImageZoomDesktop }) => ({
    display: disableImageZoomMobile ? 'block' : 'none',
    '& img': {
      display: disableImageZoomDesktop ? 'block' : 'none',
      margin: 0,
    },
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      display: 'block',
    },
  }),
  arrow: {
    position: 'absolute',
    width: '4rem',
    height: '4rem',
    top: '50%',
    transform: 'translateY(-50%)',
    margin: 0,
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent',
    '& img': {
      width: '0.9rem',
      margin: 0,
      [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
        width: '1.8rem',
      },
    },
  },
  arrowLeft: {
    left: '-0.5rem',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      left: '1rem',
    },
  },
  arrowRight: {
    right: '-0.5rem',
    '& img': {
      right: 10,
      left: 'auto',
    },
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      right: '1rem',
    },
  },
  arrowDisabled: {
    opacity: 0.3,
  },
  boxThumbs: {
    padding: '0 .2rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  thumb: {
    marginTop: '1.6rem',
    maxWidth: '4rem',
    marginRight: '0.6rem',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      marginTop: `${theme.gap}rem`,
      maxWidth: '8rem',
      marginRight: '1.5rem',
    },
  },
  thumbSelected: {
    outline: '0.1rem solid #979797',
  },
  thumbImage: {
    display: 'block',
    margin: 0,
  },
}))

export default useStyles
